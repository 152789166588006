import { render, staticRenderFns } from "./HoukanStationCard.vue?vue&type=template&id=21322ad3"
import script from "./HoukanStationCard.vue?vue&type=script&lang=js"
export * from "./HoukanStationCard.vue?vue&type=script&lang=js"
import style0 from "./HoukanStationCard.vue?vue&type=style&index=0&id=21322ad3&prod&spoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports