import { render, staticRenderFns } from "./CategorySection.vue?vue&type=template&id=eca3c712&scoped=true"
import script from "./CategorySection.vue?vue&type=script&lang=ts"
export * from "./CategorySection.vue?vue&type=script&lang=ts"
import style0 from "./CategorySection.vue?vue&type=style&index=0&id=eca3c712&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eca3c712",
  null
  
)

export default component.exports