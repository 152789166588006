
import CloseIcon from '~/static/img/icons/info-close.svg?inline';

export default {
  name: 'ConnectPrDialog',
  props: {
    promotionItem: { properties: '', analyticsUserId: '' }
  },
  components: {
    CloseIcon
  },
  data() {
    return {
      connectPrDialog: true
    };
  },
  computed: {},
  watch: {
    connectPrDialog: {
      handler: function (val, oldVal) {
        if (this.connectPrDialog) {
        } else {
          this.$emit('closeConnectPr');
        }
      },
      immediate: true //初期表示時に実行させる
    }
  },
  methods: {
    clickConnectPr() {
      // HPリンク時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: 'コネクトLPサイトクリック',
        eventAction: `${this.promotionItem.properties.company_name} (tel: ${this.promotionItem.properties.tel})`,
        eventLabel: this.promotionItem.analyticsUserId
      });
    }
  }
};
