
import { mapGetters } from 'vuex';
import { tagTypeHoukan } from '../utils/tagType';
import DataNotfound from '~/components/Atoms/DataNotFound.vue';
import ConnectprDialog from './ConnectPrDialog';
import ConnectdetailDialog from './ConnectDetailDialog';
import { LOG_LEVEL, LOG_CATEGORY } from '~/components/utils/sentry.consants';
import { Timestamp } from '@firebase/firestore';
import { DateFormatter } from '~/components/utils/DateFormatterUtil';
import { StringFormatter } from '~/components/utils/StringFormatterUtil';
import usePublicRelations from '@/components/molecules/hooks/usePublicRelations';

import { DISPLAY_PHONE_DESK, DISPLAY_FAX_DESK } from '~/constants/publicRelations.constants.ts';
import PublicRelationsInformationDialog from '@/components/commons/organism/PublicRelationsInformationDialog.vue';
import VisitingNursePublicRelationsInfoSections from '@/components/commons/organism/VisitingNursePublicRelationsInfoSections.vue';
// keyがgeoData上の名称。valueが画面上の表示名という対応
const tagType = tagTypeHoukan;

const TARGET_ASSET_TYPE = '訪看';
const GA_DELAY = 1000 * 3; // wait time (msec)
const { isPublicRelationsEnabled } = usePublicRelations();
let timerId; // GA送信判定につかうタイマーを格納
let gaLastSentId; // 最後にGAを送信したクライアントID

export default {
  data() {
    return {
      connectPrDialog: false,
      connectDetailDialog: false,
      promotionItem: { properties: '', analyticsUserId: '' },
      isOpenPublicRelationsDialog: false,
      publicRelationsInfoBase: {},
      visitingNursePublicRelationsInfoSections: {
        weeklyAcceptablePersonPerDay: {},
        enrollmentType: {},
        acceptablePatientType: [],
        specializedInformationDescription: '',
        supportAreaDescription: '',
        otherSupplementation: ''
      }
    };
  },
  props: {
    publicRelationsData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    DataNotfound,
    ConnectprDialog,
    ConnectdetailDialog,
    PublicRelationsInformationDialog,
    VisitingNursePublicRelationsInfoSections
  },
  methods: {
    onMouseOver(item) {
      // map側でselectedにされたとき、こちらで検知できないので、まずcssを一律リセットする
      const selectedElements = document.getElementsByClassName('station-list__selected');
      Array.prototype.forEach.call(selectedElements, (ele) => {
        ele.setAttribute('class', 'station-list__normal');
      });
      //描画される前にマウスオーバーするとエラー
      document.getElementById(item.properties.id).setAttribute('class', 'station-list__selected');
      // mouseoverが3秒続いたらGAにデータ送信
      timerId = setTimeout(() => {
        if (item.properties.id === gaLastSentId) {
          // 連続して同じ企業名を送信しないようにする
          return;
        }
        this.$analytics({
          // send Google Analytics
          eventCategory: '個社ウォッチ',
          eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
          eventLabel: this.makeAnalyticsLabel()
        });
        gaLastSentId = item.properties.id;
      }, GA_DELAY);
      this.$store.commit('mapMarkerid', item.properties.id);
    },
    onMouseLeave(item) {
      try {
        document.getElementById(item.properties.id).setAttribute('class', 'station-list__normal');
        clearTimeout(timerId); // ga timer
      } catch (e) {
        // 頻繁に切り替えると発生しやすい。無視してよい。sentryも送らない
      }
    },
    clickHomepage(item) {
      // HPリンク時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: '公式サイトクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    clickRoute(item) {
      // ルートを探す押下時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ルートを探すクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    visibleHomepage(item) {
      let visible = false;
      try {
        visible =
          item.properties.url !== '' &&
          item.properties.url !== '不明' &&
          typeof item.properties.url !== 'undefined';
      } catch (e) {
        this.$sentry.captureException(e);
      }
      return visible;
    },
    makeGoogleRouteUrl(item) {
      // google ルート検索のURL生成
      const lat = item.geometry.coordinates[1];
      const lng = item.geometry.coordinates[0];
      return `https://www.google.co.jp/maps/dir//${lat},${lng}/@${lat},${lng},17z`;
    },
    visibleLabels(item) {
      const labels = [];
      try {
        for (let key in tagType) {
          // ラベルが重複している場合は以降の処理をスキップして次のループへ
          if (labels.includes(tagType[key])) continue;

          // 訪問看護はPTOTSTの人数でラベル判定
          if (Number(item.properties[key]) > 0) {
            labels.push(tagType[key]);
          }
        }
      } catch (e) {
        this.$sentry.captureException(e);
      }
      return labels;
    },
    generateFormatDate(facility) {
      try {
        if (!this.isEnabledPublicRelations(facility)) {
          const masterInfoUpdate = facility.properties.update; // マスタの更新日(string)
          return StringFormatter.facilityUpdateDate(masterInfoUpdate);
        }
        const publicRelationsUpdate = this.filterPublicRelationsByFacilityId(
          facility.properties.id
        );
        return DateFormatter.facilityUpdateDate(publicRelationsUpdate?.lastUpdateAt.toDate());
      } catch (error) {
        this.$sentry.addBreadcrumb({
          category: LOG_CATEGORY.FACILITY_CARD,
          message: 'Failed to generateFormatDate',
          level: LOG_LEVEL.WARNING,
          data: {
            facilityData: facility,
            masterInfoUpdate: facility.properties.update,
            error: error
          }
        });
        this.$sentry.captureMessage(error, LOG_LEVEL.WARNING);
        return '更新日:';
      }
    },
    makeAnalyticsLabel() {
      let label = TARGET_ASSET_TYPE;
      if (typeof this.analyticsUserId !== 'undefined' && this.analyticsUserId !== '') {
        label += `, ${this.analyticsUserId}`;
      }
      return label;
    },
    openConnectprDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectPrDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ケアブックLP詳細を表示',
        eventAction: `(ケアブックLP詳細を表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openConnectdetailDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectDetailDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'コネクト詳細を表示',
        eventAction: `(コネクト詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeConnectprDialog() {
      this.connectPrDialog = false;
    },
    closeConnectdetailDialog() {
      this.connectDetailDialog = false;
    },
    isEnabledPublicRelations(facility) {
      const hasPublicRelations = this.hasPublicRelations(facility);
      return isPublicRelationsEnabled(this.isLogin, hasPublicRelations, facility.properties);
    },
    filterPublicRelationsByFacilityId(facilityId) {
      return this.allPublicRelationsList?.find((data) => data.connectId === facilityId);
    },
    hasPublicRelations(facility) {
      return Boolean(this.filterPublicRelationsByFacilityId(facility.properties.id));
    },
    openPublicRelationsDialog(facility) {
      const PublicRelations = this.filterPublicRelationsByFacilityId(facility.properties.id);

      this.visitingNursePublicRelationsInfoSections = {
        weeklyAcceptablePersonPerDay: PublicRelations.acceptablePersonPerDay,
        enrollmentType: PublicRelations.enrollmentType,
        acceptablePatientType: PublicRelations.acceptablePatientTypes,
        specializedInformationDescription: PublicRelations.specializedInformationDescription,
        supportAreaDescription: PublicRelations.supportAreaDescription,
        otherSupplementation: PublicRelations.otherSupplementation
      };

      this.publicRelationsInfoBase = {
        companyName: facility.properties.company_name,
        contactPersonName: PublicRelations.contactPersonName,
        tel: PublicRelations.tel,
        fax: PublicRelations.fax,
        telLabel: DISPLAY_PHONE_DESK[PublicRelations.telDesk],
        faxLabel: DISPLAY_FAX_DESK[PublicRelations.faxDesk],
        lastUpdateAt: new Timestamp(
          PublicRelations.lastUpdateAt.seconds,
          PublicRelations.lastUpdateAt.nanoseconds
        )
      };

      this.isOpenPublicRelationsDialog = true;
    },
    closePublicRelationsDialog() {
      this.isOpenPublicRelationsDialog = false;
    }
  },
  computed: {
    ...mapGetters({
      geodataFeatures: 'features',
      isLogin: 'loginStatus',
      analyticsUserId: 'analyticsUserId',
      myConnectStatus: 'connectStatus'
    }),
    allPublicRelationsList() {
      if (!this.isLogin) return;
      return this.publicRelationsData?.map((prData) => ({
        connectId: prData.connectId,
        acceptablePatientTypes: prData.acceptablePatientTypes,
        acceptablePersonPerDay: prData.acceptablePersonPerDay,
        contactPersonName: prData.contactPersonName,
        enrollmentType: prData.enrollmentType,
        fax: prData.fax,
        otherSupplementation: prData.otherSupplementation,
        specializedInformationDescription: prData.specializedInformationDescription,
        supportAreaDescription: prData.supportAreaDescription,
        tel: prData.tel,
        lastUpdateAt: prData.lastUpdateAt,
        telDesk: prData.telDesk,
        faxDesk: prData.faxDesk
      }));
    }
  }
};
