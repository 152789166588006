
import { db, firestoreMethods } from '~/components/settings/firebase';
import { mapGetters } from 'vuex';
import { ASSET_TYPE } from '~/components/settings/variables';
import { HomeMedicalPublicRelationsRepository } from '~/repository/HomeMedicalPublicRelationsRepository';
import { VisitingNursePublicRelationsRepository } from '~/repository/VisitingNursePublicRelationsRepository';
import SearchToolbar from '~/components/molecules/SearchToolbar.vue';
import GoogleMap from '~/components/molecules/GoogleMap.vue';
import HoukanStationCard from '~/components/molecules/HoukanStationCard.vue';
import HospitalStationCard from '~/components/molecules/HospitalStationCard.vue';
import KakaritukeStationCard from '~/components/molecules/KakaritukeStationCard.vue';
import HomeMedicalStationCard from '~/components/molecules/HomeMedicalStationCard.vue';
import RoukenStationCard from '~/components/molecules/RoukenStationCard.vue';
import TokuyouStationCard from '~/components/molecules/TokuyouStationCard.vue';
import KaigoIryouInStationCard from '~/components/molecules/KaigoIryouInStationCard.vue';
import AreaStationCard from '~/components/molecules/AreaStationCard.vue';

export default {
  name: 'OverallMapArea',
  data() {
    return {
      setFacilityCardComponent: this.$options.components.HospitalStationCard,
      homeMedicalPublicRelations: [],
      visitingNursePublicRelations: []
    };
  },
  computed: {
    ...mapGetters({
      selectedRadio: 'radios',
      isLogin: 'loginStatus'
    }),
    publicRelationsData() {
      const publicRelationsMap = new Map([
        [ASSET_TYPE.clinic, this.homeMedicalPublicRelations],
        [ASSET_TYPE.houkan, this.visitingNursePublicRelations]
      ]);
      return publicRelationsMap.get(this.selectedRadio);
    }
  },
  components: {
    SearchToolbar,
    GoogleMap,
    HospitalStationCard,
    KakaritukeStationCard,
    HoukanStationCard,
    HomeMedicalStationCard,
    RoukenStationCard,
    TokuyouStationCard,
    KaigoIryouInStationCard,
    AreaStationCard
  },
  watch: {
    selectedRadio: {
      handler: function (selectedRadioType) {
        const cardComponentMap = new Map([
          [ASSET_TYPE.houkan, this.$options.components.HoukanStationCard],
          [ASSET_TYPE.hospital, this.$options.components.HospitalStationCard],
          [ASSET_TYPE.kakarituke, this.$options.components.KakaritukeStationCard],
          [ASSET_TYPE.clinic, this.$options.components.HomeMedicalStationCard],
          [ASSET_TYPE.rouken, this.$options.components.RoukenStationCard],
          [ASSET_TYPE.tokuyou, this.$options.components.TokuyouStationCard],
          [ASSET_TYPE.kaigoiryouin, this.$options.components.KaigoIryouInStationCard],
          [ASSET_TYPE.area, this.$options.components.AreaStationCard]
        ]);

        if (this.isLogin && selectedRadioType === ASSET_TYPE.clinic) {
          this.fetchHomeMedicalPublicRelations();
        }

        if (this.isLogin && selectedRadioType === ASSET_TYPE.houkan) {
          this.fetchVisitingNursePublicRelations();
        }

        this.setFacilityCardComponent = cardComponentMap.get(selectedRadioType);
      },
      deep: true
    }
  },
  methods: {
    async fetchHomeMedicalPublicRelations() {
      if (this.homeMedicalPublicRelations?.length > 0) {
        return this.homeMedicalPublicRelations;
      }
      const homeMedicalPublicRelationsRepository = new HomeMedicalPublicRelationsRepository(
        db,
        firestoreMethods
      );
      homeMedicalPublicRelationsRepository.fetchAll().then((homeMedicalPublicRelations) => {
        this.homeMedicalPublicRelations = homeMedicalPublicRelations;
      });
    },
    async fetchVisitingNursePublicRelations() {
      if (this.visitingNursePublicRelations?.length > 0) {
        return this.visitingNursePublicRelations;
      }
      const visitingNursePublicRelationsRepository = new VisitingNursePublicRelationsRepository(
        db,
        firestoreMethods
      );

      visitingNursePublicRelationsRepository.fetchAll().then((visitingNursePublicRelations) => {
        this.visitingNursePublicRelations = visitingNursePublicRelations;
      });
    }
  }
};
