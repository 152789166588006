import { HomeMedicalPublicRelationsInformation } from '@/domain/PublicRelationsInformation/HomeMedicalPublicRelationsInformation';
import {
  FaxDesk,
  HomeMedicalAcceptablePatient,
  TelDesk
} from '~/domain/PublicRelationsInformation/publicRelationsType';
import { HomeMedicalPublicRelationsCollection } from '../firebaseShema/HomeMedicalPublicRelationsCollection';

export class HomeMedicalPublicRelationsMapper {
  public static toDomain(
    firestoreData: HomeMedicalPublicRelationsCollection,
    documentId: string
  ): HomeMedicalPublicRelationsInformation {
    return new HomeMedicalPublicRelationsInformation(
      documentId,
      firestoreData.relatedEnableHospitalId,
      firestoreData.contactPersonName,
      firestoreData.tel,
      firestoreData.fax,
      firestoreData.telDesk as TelDesk,
      firestoreData.faxDesk as FaxDesk,
      firestoreData.supportAreaDescription,
      firestoreData.specializedFieldDescription,
      firestoreData.partnerMedicalInstitutionDescription,
      firestoreData.acceptablePatientType as HomeMedicalAcceptablePatient[],
      firestoreData.acceptablePatientDescription,
      firestoreData.annexationOfHomeNursing,
      firestoreData.lastUpdateAt
    );
  }
}
