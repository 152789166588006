
import { defineComponent, PropType } from 'vue';
import { AcceptableBedInfoItemProps } from '@/types/AcceptablePatientInfoTypes';

export default defineComponent({
  props: {
    acceptableBedInfo: {
      type: Array as PropType<AcceptableBedInfoItemProps[]>,
      required: true,
      default: []
    }
  },
  setup(props) {
    const isOccupiedBed = (bedCount: number): boolean => {
      return bedCount === 0;
    };
    return {
      props,
      isOccupiedBed
    };
  }
});
