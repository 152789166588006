
import CloseIcon from '~/static/img/icons/info-close.svg?inline';
import { BUILD_TYPE } from '../settings/variables'; //定数
import { CONNECT_MODE_MAP } from '../settings/variables';

export default {
  name: 'connectDetailDialog',
  props: {
    promotionItem: { properties: '', analyticsUserId: '' }
  },
  components: {
    CloseIcon
  },
  data() {
    return {
      connectDetailDialog: true
    };
  },
  computed: {},
  watch: {
    connectDetailDialog: {
      handler: function (val, oldVal) {
        if (this.connectDetailDialog) {
        } else {
          this.$emit('closeConnectDialog');
        }
      },
      immediate: true //初期表示時に実行させる
    }
  },
  methods: {
    clickConnectDatail() {
      // HPリンク時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: 'コネクトへのリンクをクリック',
        eventAction: `${this.promotionItem.properties.company_name} (tel: ${this.promotionItem.properties.tel})`,
        eventLabel: this.promotionItem.analyticsUserId
      });
    }
  },
  computed: {
    isConnectMode() {
      // ダイアログでの表記
      switch (this.promotionItem.properties.connect_mode) {
        case CONNECT_MODE_MAP.RECEIVER: // 受け側
          return 'コネクトで退院調整できる施設です';
          break;
        case CONNECT_MODE_MAP.SENDER: // 出し側
          return 'コネクトに参加していますが、受け入れの相談は行なっていない施設です';
          break;
        case CONNECT_MODE_MAP.BOTH: // 出し側と受け側の両方の場合は受け側の表記にしておく
          return 'コネクトで退院調整できる施設です';
          break;
        default:
          // 上のどれかに当てはまるはず
          return 'コネクトで退院調整できる施設です';
      }
    },
    isBuildType() {
      let label = [];
      for (let key of this.promotionItem.properties.conncect_build_types) {
        label.push(BUILD_TYPE[key].toString());
      }
      return label;
    }
  }
};
