export const LOG_LEVEL = {
  FATAL: 'fatal',
  ERROR: 'error',
  WARNING: 'warning',
  LOG: 'log',
  INFO: 'info',
  DEBUG: 'debug'
} as const;

export const LOG_CATEGORY = {
  PUBLIC_RELATIONS: 'PUBLIC_RELATIONS',
  FACILITY_CARD: 'FACILITY_CARD',
  LOGIN: 'LOGIN'
} as const;
