
import { defineComponent, PropType } from 'vue';
import { NumberOfPeopleByEnrollmentItemProps } from '@/types/AcceptablePatientInfoTypes';

export default defineComponent({
  props: {
    numberOfPeopleByEnrollmentMap: {
      type: Array as PropType<NumberOfPeopleByEnrollmentItemProps[]>,
      required: true,
      default: []
    }
  },
  setup(props) {
    return {
      props
    };
  }
});
