
import { defineComponent, PropType } from 'vue';
import AcceptableBedInfoTable from '@/components/commons/organism/AcceptableBedInfoTable.vue';
import TextBox from '@/components/commons/molecules/TextBox.vue';
import { HospitalPublicRelationsInfoSectionsProps } from '@/types/AcceptablePatientInfoTypes';
import { useHospitalPublicRelationsInfoSections } from '@/components/commons/organism/hooks/useHospitalPublicRelationsInfoSections';
export default defineComponent({
  props: {
    hospitalPublicRelationSectionsProps: {
      type: Object as PropType<HospitalPublicRelationsInfoSectionsProps>,
      required: true,
      default: {
        description: ''
      }
    }
  },
  components: {
    TextBox,
    AcceptableBedInfoTable
  },
  setup(props) {
    const { generateDescriptionText, generateBedTypes } = useHospitalPublicRelationsInfoSections();

    return {
      props,
      generateDescriptionText,
      generateBedTypes
    };
  }
});
