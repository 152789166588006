import { Timestamp } from 'firebase/firestore';
import { CONNECT_MODE_MAP } from '~/components/settings/variables';
import { ConnectModeType, EnableHospitalType } from '~/types/GetFirebaseDataTypes';

export class PublicRelations {
  /**
   * 空床情報(施設情報)が公開対象かどうかを判定する
   */
  isEnabled(hasPublicRelations: boolean, facility: EnableHospitalType): boolean {
    return (
      hasPublicRelations &&
      this.isEnabledConnectMode(facility.connect_mode) &&
      this.isReceiveDateBeforeCurrent(facility.connect_enable_receive_date) &&
      this.isEnablePublicRelationsSetting(facility.notApplicateEditRoomAccess)
    );
  }

  private isEnabledConnectMode(connectMode: ConnectModeType): boolean {
    return connectMode === CONNECT_MODE_MAP.RECEIVER || connectMode === CONNECT_MODE_MAP.BOTH;
  }

  private isReceiveDateBeforeCurrent(enableReceiveDate: Timestamp | undefined): boolean {
    if (!enableReceiveDate) return false;
    return enableReceiveDate < Timestamp.now();
  }

  private isEnablePublicRelationsSetting(notApplicateEditRoomAccess?: boolean): boolean {
    return !notApplicateEditRoomAccess; // 未設定 or falseの場合は制限していない為trueを返す
  }
}
