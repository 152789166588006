import { DisplayCheckboxItemProps } from '@/types/AcceptablePatientInfoTypes';

export const useHomeMedicalPublicRelationsInfoSections = () => {
  const initialAcceptablePatientType = [
    { value: false, name: '小児', key: 'pediatrics' },
    { value: false, name: '輸血', key: 'bloodTransfusion' }
  ];
  const generateAcceptablePatientType = (
    acceptablePatientType: string[]
  ): DisplayCheckboxItemProps[] => {
    return initialAcceptablePatientType.map((item) => {
      return { name: item.name, value: acceptablePatientType.includes(item.key) };
    });
  };
  const displayValueAnnexationOfHomeNursing = (isAnnexation: boolean): string => {
    return isAnnexation ? '併設あり' : '併設なし';
  };
  return {
    generateAcceptablePatientType,
    displayValueAnnexationOfHomeNursing
  };
};
