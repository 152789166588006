import { BED_CATEGORY_LIST, BED_DETAIL_ASSET_TYPE } from '~/components/settings/variables';
import {
  AcceptableBedInfo,
  BedCategory,
  HospitalPublicRelationsInfoSectionsProps,
  NumberOfAcceptableBed
} from '~/types/AcceptablePatientInfoTypes';

export const useHospitalPublicRelationsInfoSections = () => {
  const generateDescriptionText = (text: string) => {
    if (text === '') {
      return 'コメントがありません';
    }
    return text;
  };

  const mappingBedCategory = (
    acceptableBedInfo: AcceptableBedInfo
  ): { [key in BedCategory]: NumberOfAcceptableBed | undefined } => {
    return {
      ippan: acceptableBedInfo.ippan,
      riha: acceptableBedInfo.riha,
      area: acceptableBedInfo.area,
      iryou: acceptableBedInfo.iryou,
      kanwa: acceptableBedInfo.kanwa,
      seisin: acceptableBedInfo.seisin,
      kaigo: acceptableBedInfo.kaigo,
      handicap: acceptableBedInfo.handicap
    };
  };

  const generateBedTypes = (
    hospitalPublicRelationSectionsProps: HospitalPublicRelationsInfoSectionsProps
  ) => {
    const bedInfo = mappingBedCategory(hospitalPublicRelationSectionsProps);
    return BED_CATEGORY_LIST.map((category) => {
      if (!bedInfo[category]) {
        return null;
      }
      return {
        type: BED_DETAIL_ASSET_TYPE[category],
        man: bedInfo[category]?.man,
        woman: bedInfo[category]?.woman
      };
    }).filter((item) => item !== null);
  };

  return {
    generateDescriptionText,
    generateBedTypes
  };
};
