import { VisitingNursePublicRelationsInformation } from '@/domain/PublicRelationsInformation/VisitingNursePublicRelationsInformation';
import { FIRESTORE_COLLECTION } from '@/repository/base/firestore.constant';
import { IVisitingNursePublicRelationsRepository } from '@/repository/interface/IVisitingNursePublicRelationsRepository';
import { Firestore } from 'firebase/firestore';
import { firestoreMethodsType } from '~/components/settings/firebase';
import { VisitingNursePublicRelationsMapper } from '~/repository/mapping/VisitingNursePublicRelationsMapper';
import { FailedtoFetchException } from './Exception/FailedtoFetchException';
import { VisitingNursePublicRelationsCollection } from './firebaseShema/VisitingNursePublicRelationsCollection';

export class VisitingNursePublicRelationsRepository
  implements IVisitingNursePublicRelationsRepository
{
  private readonly COLLECTION_PATH = FIRESTORE_COLLECTION.VISITING_NURSE_ACCEPTABLE_PATIENT;
  private readonly db: Firestore;
  private readonly firestoreMethods: firestoreMethodsType;

  constructor(db: Firestore, firestore: firestoreMethodsType) {
    this.db = db;
    this.firestoreMethods = firestore;
  }

  async fetchAll(): Promise<VisitingNursePublicRelationsInformation[]> {
    try {
      const { collectionGroup, getDocs, query } = this.firestoreMethods;
      const q = query(collectionGroup(this.db, this.COLLECTION_PATH));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs
        .filter((doc) => doc.exists())
        .map((doc) => {
          const data = doc.data() as VisitingNursePublicRelationsCollection;
          return VisitingNursePublicRelationsMapper.toDomain(data, doc.id);
        });
    } catch (error) {
      throw new FailedtoFetchException(
        `Failed to featch VisitingNursePublicRelation, error:${error}`
      );
    }
  }
}
