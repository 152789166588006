export const publicRelationsFieldData = {
  visitingNurseAcceptablePatientCategory: {
    pediatrics: 'pediatrics',
    handicap: 'palliativeCare',
    psychiatry: 'psychiatry'
  },
  homeMedicalAcceptablePatientCategory: {
    pediatrics: 'pediatrics',
    bloodTransfusion: 'bloodTransfusion'
  },
  supportStatus: {
    supported: 'supported',
    unsupported: 'unsupported',
    undecided: 'undecided'
  }
} as const;
