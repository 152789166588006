
import { defineComponent, PropType } from 'vue';
import { DisplayCheckboxItemProps } from '@/types/AcceptablePatientInfoTypes';

export default defineComponent({
  props: {
    displayCheckboxItems: {
      type: Array as PropType<DisplayCheckboxItemProps[]>,
      required: true,
      default: []
    }
  },
  setup(props) {
    return {
      props
    };
  }
});
