import { Timestamp } from 'firebase/firestore';
import { FAX_DESK, TEL_DESK } from '~/constants/publicRelations.constants';
import { NullableTimestamp } from '~/types/NullableTimestampType';
import { IllegalArgumentException } from '../base/Exception/IllegalArgumentException';
import { publicRelationsFieldData } from './publicRelationsFieldData';

import {
  FaxDesk,
  PersonPerEnrollment,
  SupportStatus,
  SupportStatusByDayOfWeek,
  TelDesk,
  VisitingNurseAcceptablePatient
} from './publicRelationsType';

export class VisitingNursePublicRelationsInformation {
  private readonly MAX_SPECIALIZED_INFORMATION_DESCRIPTION_LENGTH = 200;
  private readonly MAX_SUPPORT_AREA_DESCRIPTION_LENGTH = 200;
  private readonly MAX_OTHER_SUPPLEMENTATION_LENGTH = 400;

  private readonly _documentId: string;
  private readonly _connectId: string;
  private readonly _contactPersonName: string;
  private readonly _tel: string;
  private readonly _fax: string = '';
  private readonly _telDesk: TelDesk;
  private readonly _faxDesk: FaxDesk;
  private readonly _acceptablePersonPerDay: SupportStatusByDayOfWeek;
  private readonly _enrollmentType: PersonPerEnrollment;
  private readonly _acceptablePatientTypes: VisitingNurseAcceptablePatient[];
  private readonly _specializedInformationDescription: string;
  private readonly _supportAreaDescription: string;
  private readonly _otherSupplementation: string;
  private readonly _lastUpdateAt: NullableTimestamp;
  constructor(
    documentId: string,
    connectId: string,
    contactPersonName: string,
    tel: string,
    fax: string,
    telDesk: TelDesk,
    faxDesk: FaxDesk,
    acceptablePersonPerDay: SupportStatusByDayOfWeek,
    enrollmentType: PersonPerEnrollment,
    acceptablePatientTypes: VisitingNurseAcceptablePatient[],
    specializedInformationDescription: string,
    supportAreaDescription: string,
    otherSupplementation: string,
    lastUpdateAt: NullableTimestamp
  ) {
    if (!this.checkTelDeskType(telDesk)) {
      throw new IllegalArgumentException(
        'telDesk type illegal.',
        'VisitingNursePublicRelationsInformation.telDesk'
      );
    }
    if (!this.checkFaxDeskType(faxDesk)) {
      throw new IllegalArgumentException(
        'faxDesk type illegal.',
        'VisitingNursePublicRelationsInformation.faxDesk'
      );
    }
    if (
      specializedInformationDescription.length > this.MAX_SPECIALIZED_INFORMATION_DESCRIPTION_LENGTH
    ) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_SPECIALIZED_INFORMATION_DESCRIPTION_LENGTH} characters.`,
        'VisitingNursePublicRelationsInformation.specializedInformationDescription'
      );
    }
    if (supportAreaDescription.length > this.MAX_SUPPORT_AREA_DESCRIPTION_LENGTH) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_SUPPORT_AREA_DESCRIPTION_LENGTH} characters.`,
        'VisitingNursePublicRelationsInformation.supportAreaDescription'
      );
    }
    if (otherSupplementation.length > this.MAX_OTHER_SUPPLEMENTATION_LENGTH) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_OTHER_SUPPLEMENTATION_LENGTH} characters.`,
        'VisitingNursePublicRelationsInformation.otherSupplementation'
      );
    }
    if (lastUpdateAt && lastUpdateAt.toDate() > Timestamp.now().toDate()) {
      throw new IllegalArgumentException(
        'lastUpdateAt must be a past date',
        'VisitingNursePublicRelationsInformation.lastUpdateAt'
      );
    }
    this._documentId = documentId;
    this._connectId = connectId;
    this._contactPersonName = contactPersonName;
    this._tel = tel;
    if (faxDesk !== FAX_DESK.UNAVAILABLE) {
      this._fax = fax;
    }
    this._telDesk = telDesk;
    this._faxDesk = faxDesk;
    this._acceptablePersonPerDay = acceptablePersonPerDay;
    this._enrollmentType = enrollmentType;
    this._acceptablePatientTypes = acceptablePatientTypes;
    this._specializedInformationDescription = specializedInformationDescription;
    this._supportAreaDescription = supportAreaDescription;
    this._otherSupplementation = otherSupplementation;
    this._lastUpdateAt = lastUpdateAt;
  }

  public static emptyInformation(): VisitingNursePublicRelationsInformation {
    return new VisitingNursePublicRelationsInformation(
      '',
      '',
      '',
      '',
      '',
      'direct',
      'unavailable',
      {
        monday: publicRelationsFieldData.supportStatus.undecided,
        tuesday: publicRelationsFieldData.supportStatus.undecided,
        wednesday: publicRelationsFieldData.supportStatus.undecided,
        thursday: publicRelationsFieldData.supportStatus.undecided,
        friday: publicRelationsFieldData.supportStatus.undecided,
        saturday: publicRelationsFieldData.supportStatus.undecided,
        sunday: publicRelationsFieldData.supportStatus.undecided
      },
      {
        nurse: null,
        physicalTherapist: null,
        occupationalTherapist: null,
        speechTherapist: null
      },
      [],
      '',
      '',
      '',
      null
    );
  }

  public static transPatientType(categories: string[]): VisitingNurseAcceptablePatient[] {
    const checkCategory: string[] = Object.values(
      publicRelationsFieldData.visitingNurseAcceptablePatientCategory
    );
    if (categories.length === 0) {
      return [];
    }
    const result: VisitingNurseAcceptablePatient[] = categories.map((category) => {
      if (checkCategory.includes(category)) {
        return category as VisitingNurseAcceptablePatient;
      } else {
        throw new IllegalArgumentException('unExpected patient type is included', category);
      }
    });
    return result;
  }

  public static transSupportStatus(status: string): SupportStatus {
    const checkStatus: string[] = Object.values(publicRelationsFieldData.supportStatus);
    if (checkStatus.includes(status)) {
      return status as SupportStatus;
    } else {
      throw new IllegalArgumentException('unExpected support status is included', status);
    }
  }

  private checkTelDeskType(telDesk: string): boolean {
    return Object.values(TEL_DESK).includes(telDesk as TelDesk);
  }

  private checkFaxDeskType(faxDesk: string): boolean {
    return Object.values(FAX_DESK).includes(faxDesk as FaxDesk);
  }

  get documentId(): string {
    return this._documentId;
  }

  get connectId(): string {
    return this._connectId;
  }

  get contactPersonName(): string {
    return this._contactPersonName;
  }

  get tel(): string {
    return this._tel;
  }

  get fax(): string {
    return this._fax;
  }

  get telDesk(): TelDesk {
    return this._telDesk;
  }

  get faxDesk(): FaxDesk {
    return this._faxDesk;
  }

  get acceptablePersonPerDay(): SupportStatusByDayOfWeek {
    return this._acceptablePersonPerDay;
  }

  get enrollmentType(): PersonPerEnrollment {
    return this._enrollmentType;
  }

  get acceptablePatientTypes(): VisitingNurseAcceptablePatient[] {
    return this._acceptablePatientTypes;
  }

  get specializedInformationDescription(): string {
    return this._specializedInformationDescription;
  }

  get supportAreaDescription(): string {
    return this._supportAreaDescription;
  }

  get otherSupplementation(): string {
    return this._otherSupplementation;
  }

  get lastUpdateAt(): NullableTimestamp {
    return this._lastUpdateAt;
  }
}
