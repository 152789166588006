import { HomeMedicalPublicRelationsInformation } from '@/domain/PublicRelationsInformation/HomeMedicalPublicRelationsInformation';
import { FIRESTORE_COLLECTION } from '@/repository/base/firestore.constant';
import { IHomeMedicalPublicRelationsRepository } from '@/repository/interface/IHomeMedicalPublicRelationsRepository';
import { Firestore } from 'firebase/firestore';
import { firestoreMethodsType } from '~/components/settings/firebase';
import { FailedtoFetchException } from './Exception/FailedtoFetchException';
import { HomeMedicalPublicRelationsCollection } from './firebaseShema/HomeMedicalPublicRelationsCollection';
import { HomeMedicalPublicRelationsMapper } from './mapping/HomeMedicalPublicRelationsMapper';

export class HomeMedicalPublicRelationsRepository implements IHomeMedicalPublicRelationsRepository {
  private readonly COLLECTION_PATH = FIRESTORE_COLLECTION.HOME_MEDICAL_PUBLIC_RELATIONS;
  private readonly db: Firestore;
  private readonly firestoreMethods: firestoreMethodsType;

  constructor(db: Firestore, firestore: firestoreMethodsType) {
    this.db = db;
    this.firestoreMethods = firestore;
  }

  async fetchAll(): Promise<HomeMedicalPublicRelationsInformation[]> {
    try {
      const { collectionGroup, getDocs, query } = this.firestoreMethods;
      const q = query(collectionGroup(this.db, this.COLLECTION_PATH));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs
        .filter((doc) => doc.exists())
        .map((doc) => {
          const data = doc.data() as HomeMedicalPublicRelationsCollection;
          return HomeMedicalPublicRelationsMapper.toDomain(data, doc.id);
        });
    } catch (error) {
      throw new FailedtoFetchException(
        `Failed to featch HomeMedicalPublicRelation, error:${error}`
      );
    }
  }
}
