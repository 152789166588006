import moment from 'moment';

export class StringFormatter {
  /**
   * 施設更新日をフォーマットする
   * 更新日がDate型で扱われていない(string型)ため、DateFormatterとは分けている
   */
  static facilityUpdateDate(updateDate: string): string {
    if (!moment(updateDate, 'YYYY/MM/DD HH:mm').isValid()) {
      return '更新日:';
    }
    return moment(updateDate, 'YYYY/MM/DD HH:mm').format('更新日: YYYY/MM/DD');
  }
}
