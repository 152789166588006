import { PublicRelations } from '~/domain/PublicRelationsInformation/PublicRelations';
import { EnableHospitalType } from '~/types/GetFirebaseDataTypes';

const usePublicRelations = () => {
  const publicRelations = new PublicRelations();

  const isPublicRelationsEnabled = (
    loginStatus: boolean,
    hasPublicRelations: boolean,
    facility: EnableHospitalType
  ): boolean => {
    return loginStatus && publicRelations.isEnabled(hasPublicRelations, facility);
  };

  return {
    isPublicRelationsEnabled
  };
};

export default usePublicRelations;
