
import { defineComponent, PropType } from 'vue';
import { convertTimestampToStringDate } from '@/utils/date.utils';
import { Timestamp } from '@firebase/firestore';
import { BasePublicRelationsInformation } from '@/types/AcceptablePatientInfoTypes';
import { ADVERTISE_COMPLIANCE } from '@/constants/publicRelations.constants';

export default defineComponent({
  props: {
    isOpenDialog: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false
    },
    baseDialogProps: {
      type: Object as PropType<BasePublicRelationsInformation>,
      required: true,
      default: {
        companyName: '',
        lastUpdateAt: new Timestamp(0, 0),
        contactPersonName: '',
        tel: '',
        fax: '',
        telLabel: '',
        faxLabel: ''
      }
    }
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit('close');
    };
    const displayLastUpdateAt = (timestamp: Timestamp) => {
      return convertTimestampToStringDate(timestamp);
    };
    return {
      props,
      closeModal,
      displayLastUpdateAt,
      ADVERTISE_COMPLIANCE
    };
  }
});
