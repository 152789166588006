var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"public-relations-info-section"},[_c('div',{staticClass:"public-relations-info-section__left-content"},[_c('category-section',{attrs:{"sectionLabel":"対応可能患者様"}},[_c('display-checkbox',{attrs:{"displayCheckboxItems":_vm.generateAcceptablePatientType(
            _vm.props.visitingNursePublicRelationsInfoSectionsProps.acceptablePatientType
          )}})],1),_vm._v(" "),_c('category-section',{staticClass:"public-relations-info-section__unit",attrs:{"sectionLabel":"空き状況"}},[_c('weekly-acceptable-person-per-day',{attrs:{"weeklyAcceptablePersonPerDayMap":_vm.generateWeeklyAcceptablePersonPerDayMap(
            _vm.props.visitingNursePublicRelationsInfoSectionsProps.weeklyAcceptablePersonPerDay
          )}})],1),_vm._v(" "),_c('category-section',{staticClass:"public-relations-info-section__unit",attrs:{"sectionLabel":"在籍職種"}},[_c('number-of-people-by-enrollment',{attrs:{"numberOfPeopleByEnrollmentMap":_vm.generateNumberOfPeopleByEnrollmentMap(
            _vm.props.visitingNursePublicRelationsInfoSectionsProps.enrollmentType
          )}})],1)],1),_vm._v(" "),_c('div',{staticClass:"public-relations-info-section__right-content"},[_c('category-section',{attrs:{"sectionLabel":"対応エリア"}},[_c('text-box',{attrs:{"text":_vm.visitingNursePublicRelationsInfoSectionsProps.supportAreaDescription}})],1),_vm._v(" "),_c('category-section',{staticClass:"public-relations-info-section__unit",attrs:{"sectionLabel":"専門／認定情報"}},[_c('text-box',{attrs:{"text":_vm.props.visitingNursePublicRelationsInfoSectionsProps.specializedInformationDescription}})],1),_vm._v(" "),_c('category-section',{staticClass:"public-relations-info-section__unit",attrs:{"sectionLabel":"その他"}},[_c('text-box',{attrs:{"text":_vm.props.visitingNursePublicRelationsInfoSectionsProps.otherSupplementation}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }