import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import {
  collectionGroup,
  connectFirestoreEmulator,
  getDocs,
  getFirestore,
  query
} from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: process.env.apiKey,
  authDomain: process.env.authDomain,
  databaseURL: process.env.authDomain,
  projectId: process.env.projectId
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const firestoreMethods = {
  getDocs,
  query,
  collectionGroup
};
export type firestoreMethodsType = typeof firestoreMethods;

const isEmulator = () => {
  return process.env.NODE_ENV === 'emulator';
};

// 環境変数がemulatorの場合のみEmulatorを起動する
if (isEmulator()) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8100);
}

export { auth, db, firebaseApp, firestoreMethods };
