
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    sectionLabel: {
      type: String as PropType<string>,
      required: true,
      default: ''
    }
  },
  setup(props) {
    return {
      props
    };
  }
});
