
import { defineComponent, PropType } from 'vue';
import { WeeklyAcceptablePersonPerDayItemProps, WeekDay } from '@/types/AcceptablePatientInfoTypes';
import { convertDisplayWeekly } from '@/constants/date.constants';

export default defineComponent({
  props: {
    weeklyAcceptablePersonPerDayMap: {
      type: Array as PropType<WeeklyAcceptablePersonPerDayItemProps[]>,
      required: true,
      default: []
    }
  },
  setup(props) {
    const convertWeeklyValue = (weekDay: WeekDay) => {
      return convertDisplayWeekly[weekDay];
    };
    const pickWeekendClass = (weekDay: WeekDay) => {
      if (weekDay === 'saturday') {
        return 'weekly-acceptable-person__key--saturday';
      }
      if (weekDay === 'sunday') {
        return 'weekly-acceptable-person__key--sunday';
      }
      return '';
    };
    return {
      props,
      convertWeeklyValue,
      pickWeekendClass
    };
  }
});
