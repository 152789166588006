import moment from 'moment';

export class DateFormatter {
  static facilityUpdateDate(updateDate: Date): string {
    if (!moment(updateDate).isValid()) {
      return '更新日:';
    }
    return moment(updateDate, 'YYYY/MM/DD HH:mm').format('更新日: YYYY/MM/DD');
  }
}
