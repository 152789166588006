import { Timestamp } from 'firebase/firestore';

export const convertTimestampToStringDate = (timestamp: Timestamp): string => {
  if (!(timestamp instanceof Timestamp)) {
    return '';
  }
  const date = timestamp.toDate();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}年${month}月${day}日 ${hours}:${minutes}`;
};
