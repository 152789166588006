
import { mapGetters } from 'vuex';
import DataNotfound from '~/components/Atoms/DataNotFound.vue';
import ConnectprDialog from './ConnectPrDialog';
import ConnectdetailDialog from './ConnectDetailDialog';
import { LOG_LEVEL, LOG_CATEGORY } from '~/components/utils/sentry.consants';
import { DISPLAY_PHONE_DESK, DISPLAY_FAX_DESK } from '~/constants/publicRelations.constants.ts';
import PublicRelationsInformationDialog from '@/components/commons/organism/PublicRelationsInformationDialog.vue';
import HomeMedicalPublicRelationsInfoSections from '@/components/commons/organism/HomeMedicalPublicRelationsInfoSections.vue';
import { Timestamp } from '@firebase/firestore';
import { DateFormatter } from '~/components/utils/DateFormatterUtil';
import { StringFormatter } from '~/components/utils/StringFormatterUtil';
import usePublicRelations from '@/components/molecules/hooks/usePublicRelations';

const { isPublicRelationsEnabled } = usePublicRelations();
const TARGET_ASSET_TYPE = '訪診';
const UNKNOWN_MESSAGE = '不明';
const GA_DELAY = 1000 * 3; // wait time (msec)
let timerId; // GA送信判定につかうタイマーを格納
let gaLastSentId; // 最後にGAを送信したクライアントID

export default {
  name: 'HomeMedicalStationCard',
  data() {
    return {
      isActive: false,
      loading: false,
      detailInfo: {},
      connectPrDialog: false,
      connectDetailDialog: false,
      promotionItem: { properties: '', analyticsUserId: '' },
      isOpenPublicRelationsDialog: false,
      publicRelationsInfoBase: {},
      homeMedicalPublicRelationsInfoSections: {
        supportAreaDescription: '',
        specializedFieldDescription: '',
        partnerMedicalInstitutionDescription: '',
        acceptablePatientType: [],
        acceptablePatientDescription: '',
        annexationOfHomeNursing: false
      }
    };
  },
  props: {
    publicRelationsData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    DataNotfound,
    ConnectprDialog,
    ConnectdetailDialog,
    PublicRelationsInformationDialog,
    HomeMedicalPublicRelationsInfoSections
  },
  computed: {
    ...mapGetters({
      geodataFeatures: 'features',
      isLogin: 'loginStatus',
      analyticsUserId: 'analyticsUserId',
      myConnectStatus: 'connectStatus'
    }),
    getUnknownMessage() {
      return UNKNOWN_MESSAGE;
    },
    allPublicRelationsList() {
      if (!this.isLogin) return;
      return this.publicRelationsData?.map((prData) => ({
        connectId: prData.connectId,
        acceptablePatientDescription: prData.acceptablePatientDescription,
        acceptablePatientType: prData.acceptablePatientType,
        annexationOfHomeNursing: prData.annexationOfHomeNursing,
        contactPersonName: prData.contactPersonName,
        fax: prData.fax,
        partnerMedicalInstitutionDescription: prData.partnerMedicalInstitutionDescription,
        specializedFieldDescription: prData.specializedFieldDescription,
        supportAreaDescription: prData.supportAreaDescription,
        tel: prData.tel,
        lastUpdateAt: prData.lastUpdateAt,
        telDesk: prData.telDesk,
        faxDesk: prData.faxDesk
      }));
    }
  },
  methods: {
    totalPtCount(item) {
      return item.properties['合計診療患者数'] || this.getUnknownMessage;
    },
    totalMitoriCount(item) {
      return item.properties['看取り件数(総数)'] || this.getUnknownMessage;
    },
    homeMitoriCount(item) {
      return item.properties['看取り件数(自宅)'] || this.getUnknownMessage;
    },
    otherMitoriCount(item) {
      const count = this.totalMitoriCount(item) - this.homeMitoriCount(item);
      return count ? count : 0;
    },
    isHospital(item) {
      return item.properties['支援病'] && item.properties['支援病'].length;
    },
    makeAnalyticsLabel() {
      let label = TARGET_ASSET_TYPE;
      if (typeof this.analyticsUserId !== 'undefined' && this.analyticsUserId !== '') {
        label += `, ${this.analyticsUserId}`;
      }
      return label;
    },
    visibleHomepage(item) {
      let visible = false;
      try {
        visible =
          item.properties.url !== '' &&
          item.properties.url !== '不明' &&
          typeof item.properties.url !== 'undefined';
      } catch (e) {
        this.$sentry.captureException(e);
      }
      return visible;
    },
    generateFormatDate(facility) {
      try {
        if (!this.isEnabledPublicRelations(facility)) {
          const masterInfoUpdate = facility.properties.update; // マスタの更新日(string)
          return StringFormatter.facilityUpdateDate(masterInfoUpdate);
        }
        const publicRelationsUpdate = this.filterPublicRelationsByFacilityId(
          facility.properties.id
        );
        return DateFormatter.facilityUpdateDate(publicRelationsUpdate?.lastUpdateAt.toDate());
      } catch (error) {
        this.$sentry.addBreadcrumb({
          category: LOG_CATEGORY.FACILITY_CARD,
          message: 'Failed to generateFormatDate',
          level: LOG_LEVEL.WARNING,
          data: {
            facilityData: facility,
            masterInfoUpdate: facility.properties.update,
            error: error
          }
        });
        this.$sentry.captureMessage(error, LOG_LEVEL.WARNING);
        return '更新日:';
      }
    },
    onMouseOver(item) {
      // map側でselectedにされたとき、こちらで検知できないので、まずcssを一律リセットする
      const selectedElements = document.getElementsByClassName('station-list__selected');
      if (selectedElements) {
        Array.prototype.forEach.call(selectedElements, (ele) => {
          ele.setAttribute('class', 'station-list__normal');
        });
      }

      document.getElementById(item.properties.id).setAttribute('class', 'station-list__selected');
      // mouseoverが3秒続いたらGAにデータ送信
      timerId = setTimeout(() => {
        if (item.properties.id === gaLastSentId) {
          // 連続して同じ企業名を送信しないようにする
          return;
        }
        this.$analytics({
          // send Google Analytics
          eventCategory: '個社ウォッチ',
          eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
          eventLabel: this.makeAnalyticsLabel()
        });
        gaLastSentId = item.properties.id;
      }, GA_DELAY);

      this.$store.commit('mapMarkerid', item.properties.id);
    },
    onMouseLeave(item) {
      try {
        document.getElementById(item.properties.id).setAttribute('class', 'station-list__normal');
        clearTimeout(timerId); // ga timer
      } catch (e) {
        // 頻繁に切り替えると発生しやすい。無視してよい。sentryも送らない
      }
    },
    clickHomepage(item) {
      // HPリンク時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: '公式サイトクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    clickRoute(item) {
      // ルートを探す押下時にga送信
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ルートを探すクリック',
        eventAction: `${item.properties.company_name} (tel: ${item.properties.tel})`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    makeGoogleRouteUrl(item) {
      // google ルート検索のURL生成
      const lat = item.geometry.coordinates[1];
      const lng = item.geometry.coordinates[0];
      return `https://www.google.co.jp/maps/dir//${lat},${lng}/@${lat},${lng},17z`;
    },
    openConnectprDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectPrDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'ケアブックLP詳細を表示',
        eventAction: `(ケアブックLP詳細を表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    openConnectdetailDialog(item) {
      this.promotionItem = {
        properties: item.properties,
        analyticsUserId: this.makeAnalyticsLabel()
      };
      this.connectDetailDialog = true;
      this.$analytics({
        // send Google Analytics
        eventCategory: 'コネクト詳細を表示',
        eventAction: `(コネクト詳細表示)${item.properties.company_name}`,
        eventLabel: this.makeAnalyticsLabel()
      });
    },
    closeConnectprDialog() {
      this.connectPrDialog = false;
    },
    closeConnectdetailDialog() {
      this.connectDetailDialog = false;
    },
    closePublicRelationsDialog() {
      this.isOpenPublicRelationsDialog = false;
    },
    isEnabledPublicRelations(facility) {
      const hasPublicRelations = this.hasPublicRelations(facility);
      return isPublicRelationsEnabled(this.isLogin, hasPublicRelations, facility.properties);
    },
    filterPublicRelationsByFacilityId(facilityId) {
      return this.allPublicRelationsList?.find((data) => data.connectId === facilityId);
    },
    hasPublicRelations(facility) {
      return Boolean(this.filterPublicRelationsByFacilityId(facility.properties.id));
    },
    openPublicRelationsDialog(facility) {
      const PublicRelations = this.filterPublicRelationsByFacilityId(facility.properties.id);

      this.homeMedicalPublicRelationsInfoSections = {
        supportAreaDescription: PublicRelations.supportAreaDescription,
        specializedFieldDescription: PublicRelations.specializedFieldDescription,
        partnerMedicalInstitutionDescription: PublicRelations.partnerMedicalInstitutionDescription,
        acceptablePatientType: PublicRelations.acceptablePatientType,
        acceptablePatientDescription: PublicRelations.acceptablePatientDescription,
        annexationOfHomeNursing: PublicRelations.annexationOfHomeNursing
      };

      this.publicRelationsInfoBase = {
        companyName: facility.properties.company_name,
        contactPersonName: PublicRelations.contactPersonName,
        tel: PublicRelations.tel,
        fax: PublicRelations.fax,
        telLabel: DISPLAY_PHONE_DESK[PublicRelations.telDesk],
        faxLabel: DISPLAY_FAX_DESK[PublicRelations.faxDesk],
        lastUpdateAt: new Timestamp(
          PublicRelations.lastUpdateAt.seconds,
          PublicRelations.lastUpdateAt.nanoseconds
        )
      };
      this.isOpenPublicRelationsDialog = true;
    }
  }
};
