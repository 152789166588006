
import { defineComponent, PropType } from 'vue';
import CategorySection from '@/components/commons/molecules/CategorySection.vue';
import WeeklyAcceptablePersonPerDay from '@/components/commons/organism/WeeklyAcceptablePersonPerDay.vue';
import DisplayCheckbox from '@/components/commons/molecules/DisplayCheckbox.vue';
import NumberOfPeopleByEnrollment from '@/components/commons/organism/NumberOfPeopleByEnrollment.vue';
import TextBox from '@/components/commons/molecules/TextBox.vue';
import { VisitingNursePublicRelationsInfoSectionsProps } from '@/types/AcceptablePatientInfoTypes';
import { useVisitingNursePublicRelationsInfoSections } from '@/components/commons/organism/hooks/useVisitingNursePublicRelationsInfoSections';
export default defineComponent({
  props: {
    visitingNursePublicRelationsInfoSectionsProps: {
      type: Object as PropType<VisitingNursePublicRelationsInfoSectionsProps>,
      required: true,
      default: {
        weeklyAcceptablePersonPerDay: {},
        enrollmentType: {},
        acceptablePatientType: [],
        specializedInformationDescription: '',
        supportAreaDescription: '',
        otherSupplementation: ''
      }
    }
  },
  components: {
    CategorySection,
    WeeklyAcceptablePersonPerDay,
    DisplayCheckbox,
    NumberOfPeopleByEnrollment,
    TextBox
  },
  setup(props) {
    const {
      generateNumberOfPeopleByEnrollmentMap,
      generateAcceptablePatientType,
      generateWeeklyAcceptablePersonPerDayMap
    } = useVisitingNursePublicRelationsInfoSections();
    return {
      props,
      generateNumberOfPeopleByEnrollmentMap,
      generateAcceptablePatientType,
      generateWeeklyAcceptablePersonPerDayMap
    };
  }
});
