/**
 * メソッドに渡された引数が不適切である場合、この例外がスローする
 */
export class IllegalArgumentException extends Error {
  constructor(
    message: string,
    readonly argument: string
  ) {
    super(message + ' argument: ' + argument);
    this.name = 'IllegalArgumentException';
  }
}
