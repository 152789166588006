import { Timestamp } from 'firebase/firestore';
import { FAX_DESK, TEL_DESK } from '~/constants/publicRelations.constants';
import { NullableTimestamp } from '~/types/NullableTimestampType';
import { IllegalArgumentException } from '../base/Exception/IllegalArgumentException';
import { publicRelationsFieldData } from './publicRelationsFieldData';
import { FaxDesk, HomeMedicalAcceptablePatient, TelDesk } from './publicRelationsType';

export class HomeMedicalPublicRelationsInformation {
  private readonly MAX_SUPPORT_AREA_DESCRIPTION_LENGTH = 200;
  private readonly MAX_SPECIALIZED_FIELD_DESCRIPTION_LENGTH = 200;
  private readonly MAX_PARTNER_MEDICAL_INSTITUTION_DESCRIPTION_LENGTH = 200;
  private readonly MAX_ACCEPTABLE_PATIENT_DESCRIPTION_LENGTH = 400;

  private readonly _documentId: string;
  private readonly _connectId: string;
  private readonly _contactPersonName: string;
  private readonly _tel: string;
  private readonly _fax: string = '';
  private readonly _telDesk: TelDesk;
  private readonly _faxDesk: FaxDesk;
  private readonly _supportAreaDescription: string;
  private readonly _specializedFieldDescription: string;
  private readonly _partnerMedicalInstitutionDescription: string;
  private readonly _acceptablePatientType: HomeMedicalAcceptablePatient[];
  private readonly _acceptablePatientDescription: string;
  private readonly _annexationOfHomeNursing: boolean;
  private readonly _lastUpdateAt: NullableTimestamp;
  constructor(
    documentId: string,
    connectId: string,
    contactPersonName: string,
    tel: string,
    fax: string,
    telDesk: TelDesk,
    faxDesk: FaxDesk,
    supportAreaDescription: string,
    specializedFieldDescription: string,
    partnerMedicalInstitutionDescription: string,
    acceptablePatientType: HomeMedicalAcceptablePatient[],
    acceptablePatientDescription: string,
    annexationOfHomeNursing: boolean,
    lastUpdateAt: NullableTimestamp
  ) {
    if (!this.checkTelDeskType(telDesk)) {
      throw new IllegalArgumentException(
        'telDesk type illegal.',
        'homeMedicalPublicRelationsInformation.telDesk'
      );
    }
    if (!this.checkFaxDeskType(faxDesk)) {
      throw new IllegalArgumentException(
        'faxDesk type illegal.',
        'homeMedicalPublicRelationsInformation.faxDesk'
      );
    }
    if (supportAreaDescription.length > this.MAX_SUPPORT_AREA_DESCRIPTION_LENGTH) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_SUPPORT_AREA_DESCRIPTION_LENGTH} characters.`,
        'homeMedicalPublicRelationsInformation.supportAreaDescription'
      );
    }
    if (specializedFieldDescription.length > this.MAX_SPECIALIZED_FIELD_DESCRIPTION_LENGTH) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_SPECIALIZED_FIELD_DESCRIPTION_LENGTH} characters.`,
        'homeMedicalPublicRelationsInformation.specializedFieldDescription'
      );
    }
    if (
      partnerMedicalInstitutionDescription.length >
      this.MAX_PARTNER_MEDICAL_INSTITUTION_DESCRIPTION_LENGTH
    ) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_PARTNER_MEDICAL_INSTITUTION_DESCRIPTION_LENGTH} characters.`,
        'homeMedicalPublicRelationsInformation.partnerMedicalInstitutionDescription'
      );
    }
    if (acceptablePatientDescription.length > this.MAX_ACCEPTABLE_PATIENT_DESCRIPTION_LENGTH) {
      throw new IllegalArgumentException(
        `Too long input. Input within ${this.MAX_ACCEPTABLE_PATIENT_DESCRIPTION_LENGTH} characters.`,
        'homeMedicalPublicRelationsInformation.acceptablePatientDescription'
      );
    }
    if (lastUpdateAt && lastUpdateAt.toDate() > Timestamp.now().toDate()) {
      throw new IllegalArgumentException(
        'lastUpdateAt must be a past date.',
        'homeMedicalPublicRelationsInformation.lastUpdateAt'
      );
    }
    this._documentId = documentId;
    this._connectId = connectId;
    this._contactPersonName = contactPersonName;
    this._tel = tel;
    if (faxDesk !== FAX_DESK.UNAVAILABLE) {
      this._fax = fax;
    }
    this._telDesk = telDesk;
    this._faxDesk = faxDesk;
    this._supportAreaDescription = supportAreaDescription;
    this._specializedFieldDescription = specializedFieldDescription;
    this._partnerMedicalInstitutionDescription = partnerMedicalInstitutionDescription;
    this._acceptablePatientType = acceptablePatientType;
    this._acceptablePatientDescription = acceptablePatientDescription;
    this._annexationOfHomeNursing = annexationOfHomeNursing;
    this._lastUpdateAt = lastUpdateAt;
  }

  public static emptyInformation(): HomeMedicalPublicRelationsInformation {
    return new HomeMedicalPublicRelationsInformation(
      '',
      '',
      '',
      '',
      '',
      'direct',
      'unavailable',
      '',
      '',
      '',
      [],
      '',
      false,
      null
    );
  }

  public static transPatientType(types: string[]): HomeMedicalAcceptablePatient[] {
    const checkCategory: string[] = Object.values(
      publicRelationsFieldData.homeMedicalAcceptablePatientCategory
    );
    if (types.length === 0) {
      return [];
    }
    const result: HomeMedicalAcceptablePatient[] = types.map((type) => {
      if (checkCategory.includes(type)) {
        return type as HomeMedicalAcceptablePatient;
      } else {
        throw new IllegalArgumentException(
          'unExpected home medical acceptable patient type is included',
          type
        );
      }
    });
    return result;
  }

  private checkTelDeskType(telDesk: string): boolean {
    return Object.values(TEL_DESK).includes(telDesk as TelDesk);
  }

  private checkFaxDeskType(faxDesk: string): boolean {
    return Object.values(FAX_DESK).includes(faxDesk as FaxDesk);
  }

  get documentId(): string {
    return this._documentId;
  }

  get connectId(): string {
    return this._connectId;
  }

  get contactPersonName(): string {
    return this._contactPersonName;
  }

  get tel(): string {
    return this._tel;
  }

  get fax(): string {
    return this._fax;
  }

  get telDesk(): TelDesk {
    return this._telDesk;
  }

  get faxDesk(): FaxDesk {
    return this._faxDesk;
  }

  get supportAreaDescription(): string {
    return this._supportAreaDescription;
  }

  get specializedFieldDescription(): string {
    return this._specializedFieldDescription;
  }

  get partnerMedicalInstitutionDescription(): string {
    return this._partnerMedicalInstitutionDescription;
  }

  get acceptablePatientType(): HomeMedicalAcceptablePatient[] {
    return this._acceptablePatientType;
  }

  get acceptablePatientDescription(): string {
    return this._acceptablePatientDescription;
  }

  get annexationOfHomeNursing(): boolean {
    return this._annexationOfHomeNursing;
  }

  get lastUpdateAt(): NullableTimestamp {
    return this._lastUpdateAt;
  }
}
