
import { defineComponent, PropType } from 'vue';
import CategorySection from '@/components/commons/molecules/CategorySection.vue';
import DisplayCheckbox from '@/components/commons/molecules/DisplayCheckbox.vue';
import TextBox from '@/components/commons/molecules/TextBox.vue';
import { HomeMedicalPublicRelationsInfoSectionsProps } from '@/types/AcceptablePatientInfoTypes';
import { useHomeMedicalPublicRelationsInfoSections } from '@/components/commons/organism/hooks/useHomeMedicalPublicRelationsInfoSections';
export default defineComponent({
  props: {
    homeMedicalPublicRelationsInfoSectionsProps: {
      type: Object as PropType<HomeMedicalPublicRelationsInfoSectionsProps>,
      required: true,
      default: {
        supportAreaDescription: '',
        specializedFieldDescription: '',
        partnerMedicalInstitutionDescription: '',
        acceptablePatientType: [],
        acceptablePatientDescription: '',
        annexationOfHomeNursing: false
      }
    }
  },
  components: {
    CategorySection,
    DisplayCheckbox,
    TextBox
  },
  setup(props) {
    const { generateAcceptablePatientType, displayValueAnnexationOfHomeNursing } =
      useHomeMedicalPublicRelationsInfoSections();
    return {
      props,
      generateAcceptablePatientType,
      displayValueAnnexationOfHomeNursing
    };
  }
});
