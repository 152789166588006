import { ENROLLMENT_LIST } from '@/constants/publicRelations.constants';
import {
  AcceptablePersonSupportState,
  DisplayCheckboxItemProps,
  EnrollmentType,
  NullableNumber,
  NumberOfPeopleByEnrollmentItemProps,
  VisitingNurseAcceptablePatientType,
  WeekDay,
  WeeklyAcceptablePersonPerDayItemProps
} from '@/types/AcceptablePatientInfoTypes';
import { occupationKeyMapping } from '@/utils/visitingNurse.utils';
import { WEEKDAYS } from '~/constants/date.constants';
export const useVisitingNursePublicRelationsInfoSections = () => {
  type InitialAcceptablePatient = {
    value: boolean;
    name: string;
    key: VisitingNurseAcceptablePatientType;
  };
  const initialAcceptablePatientType: InitialAcceptablePatient[] = [
    { value: false, name: '小児', key: 'pediatrics' },
    { value: false, name: '緩和ケア', key: 'palliativeCare' },
    { value: false, name: '精神', key: 'psychiatry' }
  ];

  const convertNullToEmpty = (value: NullableNumber) => {
    if (value === null) {
      return '';
    }
    return value;
  };

  const generateNumberOfPeopleByEnrollmentMap = (
    enrollmentType: EnrollmentType
  ): NumberOfPeopleByEnrollmentItemProps[] => {
    return ENROLLMENT_LIST.map((key) => {
      return {
        name: occupationKeyMapping[key as keyof typeof occupationKeyMapping],
        value: convertNullToEmpty(enrollmentType[key])
      };
    });
  };
  const generateAcceptablePatientType = (
    acceptablePatientType: VisitingNurseAcceptablePatientType[]
  ): DisplayCheckboxItemProps[] => {
    return initialAcceptablePatientType.map((item) => {
      return {
        name: item.name,
        value: acceptablePatientType.includes(item.key)
      };
    });
  };

  const generateWeeklyAcceptablePersonPerDayMap = (acceptablePersonPerDay: {
    [key in WeekDay]: AcceptablePersonSupportState;
  }): WeeklyAcceptablePersonPerDayItemProps[] => {
    return WEEKDAYS.map((day) => {
      return {
        name: day,
        value: acceptablePersonPerDay[day]
      };
    });
  };
  return {
    generateNumberOfPeopleByEnrollmentMap,
    generateAcceptablePatientType,
    generateWeeklyAcceptablePersonPerDayMap
  };
};
