import { Enrollment } from '@/types/AcceptablePatientInfoTypes';

export const ENROLLMENT_LIST: Enrollment[] = [
  'nurse',
  'physicalTherapist',
  'occupationalTherapist',
  'speechTherapist'
];

export const TEL_DESK = {
  DIRECT: 'direct',
  MAIN: 'main'
} as const;

export const FAX_DESK = {
  DIRECT: 'direct',
  MAIN: 'main',
  UNAVAILABLE: 'unavailable'
} as const;

export const DISPLAY_PHONE_DESK = {
  direct: '直通電話',
  main: '代表電話'
} as const;

export const DISPLAY_FAX_DESK = {
  direct: '直通FAX',
  main: '代表FAX',
  unavailable: ''
} as const;

export const ADVERTISE_COMPLIANCE = `※こちらの医療機関の記載情報は、各医療機関から提供された情報に
依拠しており、弊社において正確性等の確認等をしているものではございませんのでご確認くださいませ。`;
