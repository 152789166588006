import { VisitingNursePublicRelationsInformation } from '~/domain/PublicRelationsInformation/VisitingNursePublicRelationsInformation';
import {
  FaxDesk,
  PersonPerEnrollment,
  SupportStatusByDayOfWeek,
  TelDesk,
  VisitingNurseAcceptablePatient
} from '~/domain/PublicRelationsInformation/publicRelationsType';
import { VisitingNursePublicRelationsCollection } from '../firebaseShema/VisitingNursePublicRelationsCollection';

export class VisitingNursePublicRelationsMapper {
  static toDomain(
    FirestoreData: VisitingNursePublicRelationsCollection,
    documentId: string
  ): VisitingNursePublicRelationsInformation {
    return new VisitingNursePublicRelationsInformation(
      documentId,
      FirestoreData.relatedEnableHospitalId,
      FirestoreData.contactPersonName,
      FirestoreData.tel,
      FirestoreData.fax,
      FirestoreData.telDesk as TelDesk,
      FirestoreData.faxDesk as FaxDesk,
      FirestoreData.acceptablePersonPerDay as SupportStatusByDayOfWeek,
      FirestoreData.enrollmentType as PersonPerEnrollment,
      FirestoreData.acceptablePatientTypes as VisitingNurseAcceptablePatient[],
      FirestoreData.specializedInformationDescription,
      FirestoreData.supportAreaDescription,
      FirestoreData.otherSupplementation,
      FirestoreData.lastUpdateAt
    );
  }
}
